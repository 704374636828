import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import PreetiDeveloper from "../../../components/developers/preetideveloper/PreetiDeveloper";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const PreetiDeveloperPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <PreetiDeveloper />
    </>
  )
}

export default PreetiDeveloperPage
